import React from "react";
import {
  Box,
  Center,
  Text,
  Image,
  Button,
  Heading,
  ChakraProvider,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import "./leisure.css";

const Leisure = () => {
  const images = [
    {
      src: "https://images.unsplash.com/photo-1618773928121-c32242e63f39?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      text: "Servicii de cazare",
      title: "Oferim Mai Mult Decat Doar Un Loc de Odihna",
      alt: "Servicii de cazare - Leisure",
      description:
        "Cazarea reprezinta inceputul fiecarei calatorii si ne asiguram ca incepi intr-un mod excelent. Colaboram cu o retea vasta de hoteluri, vile si apartamente pentru a-ti oferi variante de cazare care se potrivesc fiecarui stil si buget. Fie ca preferi luxul rafinat al unui hotel de 5 stele sau intimitatea unei vile private, avem solutii pentru toate gusturile.",
    },
    {
      src: "https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2074&q=80",
      text: "Servicii de transport aerian",
      title: "Experiențe Aeriane de Neuitat",
      alt: "Servicii de transport aerian - Leisure",
      description:
        "Indiferent dacă calatoriti pentru relaxare sau aventura, serviciile noastre de transport aerian asigura că veti ajunge la destinatie cu un zambet pe buze. Echipa noastra dedicată este aici să vă ofere asistentă și sa va transforme calatoria într-o experiență de neuitat. Contactati-ne astăzi pentru a începe planificarea călătoriei dvs. leisure!",
    },
    {
      src: "https://images.unsplash.com/photo-1581687719747-69e6c7507313?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      text: "Servicii de transport terestru",
      title: "Descopera Destinatii Intr-un Mod Confortabil si Elegant",
      alt: "Servicii de transport terestru - Leisure",
      description:
        "Indiferent dacă calatoriti într-un grup sau singuri, serviciile noastre de transport terestru sunt create pentru a face calatoria parte integranta a aventurilor dvs. leisure. Cu atentie la detalii și o abordare personalizata, suntem aici sa va asiguram o calatorie memorabila de la început până la sfarsit. Contactati-ne acum pentru a începe planificarea calatoriei!",
    },
    {
      src: "https://images.unsplash.com/photo-1616763866684-b215941fae5e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
      text: "Pachete Turistice",
      title: "Pachete Turistice pentru Aventuri și Relaxare",
      alt: "Pachete Turistice - Leisure",
      description:
        "Descoperă bucuria călătoriilor fără griji cu pachetele noastre turistice Leisure! În cadrul agenției noastre de turism, am creat cu pasiune și atenție fiecare pachet pentru a vă oferi experiențe de neuitat în timpul călătoriilor de agrement.Indiferent dacă doriți să explorați destinații exotice, să vă relaxați pe plajă, să vă bucurați de aventuri montane sau să explorați bogățiile culturale ale lumii, avem pachete concepute pentru a vă îndeplini fiecare dorință și vis.",
    },
    {
      src: "https://images.unsplash.com/photo-1526256262350-7da7584cf5eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      text: "Asigurari Medicale Turistice",
      title: "Protejarea sanatatii tale în vacanta",
      alt: "Asigurari Medicale Turistice",
      description:
        "Travel Time îți oferă asigurare medicală de călătorie pentru toate destinațiile. Alege acoperirea potrivită pentru tine și bucură-te de vacanța ta fără griji.Cu o asigurare medicală de călătorie de la Travel Time, ești acoperit în cazul unor evenimente neprevăzute, cum ar fi un accident, o boală sau o urgență medicală. Asigurarea include, de asemenea, asistență 24/7, astfel încât să poți obține ajutor în cazul unor probleme medicale. \n\nAlege Travel Time pentru o vacanță fără griji!",
    },
    {
      src: "https://images.unsplash.com/photo-1610647752706-3bb12232b3ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1925&q=80",
      text: "Rent-a-car",
      title: "Calatoreste liber cu Travel Time!",
      alt: "Rent-a-car",
      description:
        "Travel Time îți oferă servicii de rent-a-car pentru toate destinațiile. Alege mașina potrivită pentru tine și bucură-te de libertatea de a explora.Cu o mașină de închiriat de la Travel Time, ești liber să explorezi la tine ritmul. Poți vizita locurile pe care le dorești, fără a fi dependent de transportul public sau de tururile organizate.Travel Time îți oferă o gamă largă de mașini de închiriat, pentru toate bugetele și nevoile. De la mașini economice pentru călătoriile de zi cu zi, până la mașini de lux pentru vacanțe de lux, Travel Time are mașina perfectă pentru tine.\n\nAlege Travel Time pentru o vacanță liberă și independentă!",
    },
  ];

  const gridResponsive = useBreakpointValue({ base: "block", lg: "grid" });
  const textResponsive = useBreakpointValue({
    base: "20px",
    md: "30px",
    lg: "45px",
  });
  const descriptionResponsive = useBreakpointValue({
    base: "13px",
    md: "20px",
  });

  return (
    <>
      <ChakraProvider>
        <Link to="/leisure">
          <Center
            className="centerText"
            borderBottom="1px solid"
            fontSize={textResponsive}
            fontWeight="bold"
            color="white"
            textShadow="2px 2px 4px rgba(0, 0, 0)"
            px="6"
            mb="6"
          >
            Leisure Travel
          </Center>
        </Link>
        <Box
          display={gridResponsive}
          gridTemplateColumns="repeat(2, 1fr)"
          gap={2}
        >
          {images.map((image, index) => (
            <Box key={index} className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <Image
                    src={image.src}
                    alt={`Image ${index + 1}`}
                    w="100%"
                    h="100%"
                    objectFit="cover"
                  />
                  <Center
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    color="white"
                    textAlign="center"
                    fontSize={textResponsive}
                  >
                    {image.text}
                  </Center>
                </div>
                <div className="flip-card-back">
                  <Heading
                    width="100%"
                    mx="auto"
                    background="teal"
                    color="white"
                    mt="7px"
                    textAlign="center"
                    size="lg"
                    as="h1"
                    mb={4}
                    fontSize={descriptionResponsive}
                  >
                    {image.title}
                  </Heading>
                  <Box className="back-content">
                    <Text fontSize={descriptionResponsive}>
                      {image.description}
                    </Text>
                    <Link to="/contact">
                      <Button
                        fontSize={descriptionResponsive}
                        colorScheme="teal"
                      >
                        Contacteaza-ne
                      </Button>
                    </Link>
                  </Box>
                </div>
              </div>
            </Box>
          ))}
        </Box>
      </ChakraProvider>
    </>
  );
};

export default Leisure;

import { Box, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Rezerva = () => {
  const bgImage =
    " https://images.unsplash.com/photo-1495822892661-2ead864e1c7b?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const ExternalLink = ({ to, children }) => (
    <Link
      to={to}
      style={{ color: "inherit", textDecoration: "inherit" }}
      onClick={scrollToTop} // Optionally scroll to top when the link is clicked
    >
      {children}
    </Link>
  );

  return (
    <>
      <Box
        bgImage={`url(${bgImage})`}
        bgSize="cover" // Adjust the background size as needed
        bgRepeat="no-repeat" // Set the background repeat
        bgPosition="center" // Set the background position
        p="100px" // Add padding if needed
        textAlign="center" // Center the content horizontally
        marginBottom={10}
        marginTop={10}
      >
        <ExternalLink to="https://vacante.travel-time.ro/">
          <Button
            colorScheme="teal"
            align="center"
            textAlign="center"
            type="submit"
            mt="10px"
            size="lg"
          >
            Rezervă-ți vacanța preferată!
          </Button>
        </ExternalLink>
      </Box>
    </>
  );
};

export default Rezerva;

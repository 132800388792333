import ReusableComponentContinente from "../../components/grid-component/reusable-continente-component";

const AmericaDeSud = () => {
  const countryButtons = [
    { text: "Brazilia", value: "Brazilia" },
    { text: "Bolivia", value: "Bolivia" },
    { text: "Argentina", value: "Argentina" },
    { text: "Peru", value: "Peru" },
    { text: "Ecuador", value: "Ecuador" },
  ];

  // Define a list of continents with their names and URLs
  const continentList = [
    { name: "Europa", url: "/europa/" },
    { name: "Asia", url: "/asia" },
    { name: "America De Nord", url: "/americadenord/" },
    { name: "Africa", url: "/africa/" },
    { name: "Romania", url: "/romania/" },
    { name: "Australia si Oceania", url: "/australiasioceania/" },
    // Add more continents and URLs as needed
  ];

  const currentContinent = "America de Sud"; //setarea titlului
  const currentContinentName = "AmericaDeSud"; //prop pentru setarea endpointului din json
  const backgroundImageURL =
    "https://www.travel-time.ro/images/header-blog/America-de-Sud.jpg"; // Set the background image URL for this page

  return (
    <ReusableComponentContinente
      countryButtons={countryButtons}
      titlePage={currentContinent}
      currentContinent={currentContinentName}
      bgImage={backgroundImageURL}
      continentList={continentList}
    />
  );
};

export default AmericaDeSud;

import React, { useEffect, useState, useRef } from "react";
import { Flex, Box, Spinner } from "@chakra-ui/react";
import {
  Grid,
  GridItem,
  Image,
  Button,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";


const ReusableComponentContinente = (props) => {
  const {
    bgImage,
    currentContinent,
    continentList,
    titlePage,
    countryButtons,
  } = props;

  const [portofolioImages, setPortofolioImages] = useState([]); // Stocam pozele venite de la localHost
  const [filteredImages, setFilteredImages] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(""); // State for selected country

  //Breakpoints pentru butoane filtrate
  const displayMode = useBreakpointValue({ base: "block", md: "flex" });
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const buttonWidth = useBreakpointValue({ base: "100%", md: "auto" });
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    fetch("/destinatii-pe-continente.json").then((res) => {
      res.json().then((data) => {
        const finalResponse = data[currentContinent]; //se preia din props
        setPortofolioImages(finalResponse);
        setFilteredImages(finalResponse); // Initialize filtered images with all images
      });
    });
  }, [currentContinent]);

  useEffect(() => {
    // Filter images based on the selected country
    if (selectedCountry) {
      const filtered = portofolioImages.filter((image) =>
        image.country.toLowerCase().includes(selectedCountry.toLowerCase())
      );
      setFilteredImages(filtered);
    } else {
      setFilteredImages(portofolioImages);
    }
  }, [selectedCountry, portofolioImages]);

  const columns = useBreakpointValue({ base: 1, md: 2 });
  const fontSizeFilter = useBreakpointValue({ base: "15px", lg: "20px" });
  const heightGrid = useBreakpointValue({
    base: "250px",
    lg: "300px",
  });
  const fontSizeTitleFilter = useBreakpointValue({ base: "3xl", lg: "8xl" });
  const widthGrid = useBreakpointValue({
    base: "95%",
    lg: "75%",
  });
  const fontSizeTitlePageWidth = useBreakpointValue({
    base: "100%",
    lg: "50%",
  });

  const gridRef = useRef(null);

  const scrollToFirstItem = () => {
    // Check if the gridRef is defined
    if (gridRef.current) {
      // Scroll to the first item in the grid
      gridRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {" "}
      <Box
        bgImage={`url(${bgImage})`}
        bgSize="cover" // Adjust the background size as needed
        bgRepeat="no-repeat" // Set the background repeat
        bgPosition="center" // Set the background position
        p="100px" // Add padding if needed
      >
        <Heading
          as="h1"
          fontFamily="calibri, sans-serif"
          fontSize={fontSizeTitleFilter}
          fontWeight="bold"
          color="white"
          textAlign="center"
          padding="5px"
          borderRadius="8px"
          width={fontSizeTitlePageWidth}
          mx="auto"
          mt="5px"
        >
          {titlePage}
        </Heading>
      </Box>
      {/* List of continents */}
      <Flex justifyContent="center" mt="10px" mx="auto" wrap="wrap">
        {continentList.map((continent) => (
          <Button
            colorScheme="teal"
            marginRight="5px"
            marginBottom="5px"
            key={continent.name}
            _hover={{ bg: "gray" }}
          >
            <Link
              to={continent.url}
              style={{
                textDecoration: "none",
                color: "inherit", // Inherit the color from the parent
              }}
            >
              {continent.name}
            </Link>
          </Button>
        ))}
      </Flex>
      <Flex justifyContent="center" mt="10px" mx="auto" wrap="wrap">
        {countryButtons.map((button) => (
          <Button
            key={button.value}
            mx={{ base: "0", md: "2px" }}
            mb={displayMode === "block" ? "2px" : "0"}
            fontSize={fontSizeFilter}
            onClick={() => {
              setSelectedCountry(button.value);
              if (isSmallScreen) {
                scrollToFirstItem(); // Call the scrollToFirstItem function for small screens
              }
            }}
            colorScheme={selectedCountry === button.value ? "teal" : "gray"}
            width={buttonWidth}
            display={displayMode}
            flexDirection={flexDirection}
          >
            {button.text}
          </Button>
        ))}
      </Flex>
      {filteredImages.length > 0 ? (
        <Flex ref={gridRef} mt="10px" flexWrap="wrap" justifyContent="center">
          <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={3} w="100%">
            {filteredImages.map((image) => {
              const albumHref = "/" + image.id + "/";

              return (
                <Box key={image.id} mb={6}>
                  <GridItem position="relative" width={widthGrid} mx="auto">
                    <Box
                      className="description-title"
                      mx="auto"
                      p={2}
                      mb={3}
                      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                      display="flex"
                      justifyContent="center"
                      background="teal"
                      color="white"
                      fontWeight="bold"
                      fontSize="1.2rem"
                    >
                      {image.description}
                    </Box>
                    <a href={albumHref}>
                      <Image
                        src={image.thumbnail}
                        alt={image.alt}
                        w="100%"
                        h={heightGrid}
                        objectFit="cover"
                      />
                    </a>
                    <a href={albumHref}>
                      <Button
                        position="absolute"
                        bottom={0}
                        right={0}
                        size="sm"
                        colorScheme="black"
                        p={3}
                        mb={3}
                        mr={3}
                        fontSize="1.2rem"
                        type="button"
                        border="1px"
                        _hover={{ bg: "white", color: "black" }}
                      >
                        Mai multe informatii
                      </Button>
                    </a>
                  </GridItem>
                </Box>
              );
            })}
          </Grid>
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      )}
    </>
  );
};

export default ReusableComponentContinente;

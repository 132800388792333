import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardGroup,
} from "mdb-react-ui-kit";

const ListGroupExample = () => {
  return (
    <MDBCardGroup>
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle className="fw-bold">Adresa Locatie</MDBCardTitle>
          <MDBCardText className="fw-bold">
            TRAVEL TIME
            <br></br>
            Punct de lucru: Strada Mircea Vulcanescu nr 45,Sector 1 Bucuresti
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle className="fw-bold">Detalii Firma</MDBCardTitle>
          <MDBCardText className="fw-bold">
            <p>SC TRAVEL TIME D&R SRL </p>
            <p>
              Sediu social: Strada ANTON PANN, Nr. 2, parter, ap.3, sector 3,
              Bucuresti, Sector 3
            </p>
            <p>CIF: RO17926970</p>
            <p> Nr. Reg. Com.: J40/15226/2005</p>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle className="fw-bold">Informatii Contact</MDBCardTitle>
          <MDBCardText className="fw-bold">
            <p>Program de lucru: 09:00 - 18:00</p>
            <span>
              <strong>
                <i class="fas fa-envelope"></i> Adresa Generala:
              </strong>
              <a href="mailto:office@travel-time.ro"> office@travel-time.ro</a>
            </span>
            <br></br>
            <span>
              <strong>
                <i class="fas fa-envelope"></i> Departament Turism:
              </strong>
              <a href="mailto:turism@travel-time.ro"> turism@travel-time.ro</a>
            </span>
            <br></br>
            <span>
              <strong>
                <i class="fas fa-envelope"></i> Departament Ticketing:
              </strong>
              <a href="mailto:ticketing@travel-time.ro">
                {" "}
                ticketing@travel-time.ro
              </a>
            </span>
            <br></br>
            <span>
              <strong>
                <i class="fas fa-phone"></i> Telefon:
              </strong>
              <a href="tel:0371121272">0371 12 12 72</a>
            </span>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </MDBCardGroup>
  );
};

export default ListGroupExample;

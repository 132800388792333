import React, { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import "./google-maps.css";

const GoogleMaps = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.React_App_API_GOOGLE_MAPS_NEW,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;

  function Map() {
    const center = useMemo(
      () => ({ lat: 44.44349201566385, lng: 26.085209350277168 }),
      []
    );

    return (
      <GoogleMap
        zoom={15}
        center={center}
        mapContainerClassName="map-container"
      >
        <MarkerF position={center} />
      </GoogleMap>
    );
  }
};

export default GoogleMaps;

import React, { useEffect, useState } from "react";
import { Flex, Box, Spinner } from "@chakra-ui/react";
import {
  Grid,
  GridItem,
  Image,
  Button,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import "./informatiiutile.css";

const InformatiiUtile = () => {
  const [portofolioImages, setPortofolioImages] = useState([]); // Stocam pozele venite de la localHost

  useEffect(() => {
    fetch("/mydata.json").then((res) => {
      res.json().then((data) => {
        const finalResponse = data.informatiiutile;
        setPortofolioImages(finalResponse);
      });
    });
  }, []);

  const columns = useBreakpointValue({ base: 1, md: 2, lg: 3 });

  return (
    <>
      <Heading
        as="h1"
        fontFamily="calibri, sans-serif"
        fontSize="4xl"
        fontWeight="bold"
        color="white"
        textAlign="center"
        padding="5px"
        bg="teal.500"
        borderRadius="8px"
        width="50%"
        mx="auto"
        mt="5px"
      >
        Informatii Utile
      </Heading>
      {portofolioImages.length > 0 ? (
        <Flex mt="10px" flexWrap="wrap" justifyContent="center">
          <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={3} w="100%">
            {portofolioImages.map((image) => {
              const albumHref = "/" + image.id + "/";

              return (
                <Box key={image.id} mb={6}>
                  <GridItem position="relative" width="75%" mx="auto">
                    <Box
                      className="description-title"
                      mx="auto"
                      p={2}
                      mb={3}
                      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                      display="flex"
                      justifyContent="center"
                      background="teal"
                      color="white"
                      fontWeight="bold"
                      fontSize="1.2rem"
                    >
                      {image.description}
                    </Box>
                    <a href={albumHref}>
                      <Image
                        src={image.thumbnail}
                        alt={image.alt}
                        w="100%"
                        h="250px"
                        objectFit="cover"
                      />
                    </a>
                    <a href={albumHref}>
                      <Button
                        position="absolute"
                        bottom={0}
                        right={0}
                        size="sm"
                        colorScheme="black"
                        p={3}
                        mb={3}
                        mr={3}
                        fontSize="1.2rem"
                        type="button"
                        border="1px"
                        _hover={{ bg: "white", color: "black" }}
                      >
                        Mai multe informatii
                      </Button>
                    </a>
                  </GridItem>
                </Box>
              );
            })}
          </Grid>
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      )}
    </>
  );
};

export default InformatiiUtile;

import ReusableComponentContinente from "../../components/grid-component/reusable-continente-component";


const Romania = () => {
  const countryButtons = [
    { text: "Oltenia", value: "Oltenia" },
    { text: "Maramures", value: "Maramures" },
    { text: "Transilvania", value: "Transilvania" },
    { text: "Dobrogea", value: "Dobrogea" },
  ];

  const continentList = [
    { name: "Asia", url: "/asia/" },
    { name: "America De Sud", url: "/americadesud/" },
    { name: "America De Nord", url: "/americadesud/" },
    { name: "Africa", url: "/africa/" },
    { name: "Europa", url: "/europa/" },
    { name: "Australia si Oceania", url: "/australiasioceania/" },
    // Add more continents and URLs as needed
  ];

  const currentContinent = "Romania";
  const backgroundImageURL =
    "https://www.travel-time.ro/images/header-blog/Romania.jpg"; // Set the background image URL for this page

  return (
    <ReusableComponentContinente
      countryButtons={countryButtons}
      titlePage={currentContinent}
      currentContinent={currentContinent}
      bgImage={backgroundImageURL}
      continentList={continentList}
    />
  );
};

export default Romania;

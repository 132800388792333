import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Box,
  Spinner,
  Grid,
  GridItem,
  Image,
  Center,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

const BlogPrimaPagina = () => {
  const [portofolioImages, setPortofolioImages] = useState([]); // Stocam pozele venite de la localHost

  useEffect(() => {
    fetch("/blogdata.json").then((res) => {
      res.json().then((data) => {
        const finalResponse = data.blogdata;
        setPortofolioImages(finalResponse);
      });
    });
  }, []);

  const history = useNavigate();

  const handleButtonClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Navigate to the '/vacante' route
    history.push("/vacante");
  };

  const textResponsive = useBreakpointValue({
    base: "20px",
    md: "30px",
    lg: "35px",
  });
  const widthResponsive = useBreakpointValue({
    base: "100%",
    lg: "50%",
  });

  const widthResponsiveBlogPosts = useBreakpointValue({
    base: "100%",
    lg: "90%",
  });

  //grid columns
  const columns = useBreakpointValue({ base: 1, lg: 2 });
  const heightImageGrid = useBreakpointValue({ base: "250px", lg: "450px" });
  return (
    <>
      <Center
        className="centerText"
        borderBottom="4px solid teal"
        w={widthResponsive}
        mx="auto"
        fontSize={textResponsive}
        fontWeight="bold"
        color="white"
        textShadow="2px 2px 4px rgba(0, 128, 128)"
        px="6"
        mb="6"
        as="h2"
      >
        Blog - Postari Populare
      </Center>
      <Center>
        <Link to="/blog">
          <Button onClick={handleButtonClick} color="white" colorScheme="teal">
            Mai multe postari...
          </Button>
        </Link>
      </Center>
      {portofolioImages.length > 0 ? (
        <Flex mt="10px" flexWrap="wrap" justifyContent="center">
          <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
            {portofolioImages.map((image) => {
              const albumHref = "/" + image.id + "/";

              return (
                <Box key={image.id} mb={6}>
                  <GridItem
                    position="relative"
                    width={widthResponsiveBlogPosts}
                    mx="auto"
                  >
                    <Box
                      className="description-title"
                      mx="auto"
                      p={2}
                      mb={3}
                      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                      display="flex"
                      justifyContent="center"
                      background="teal"
                      color="white"
                      fontWeight="bold"
                      fontSize="1.2rem"
                    >
                      {image.description}
                    </Box>
                    <a href={albumHref}>
                      <Image
                        src={image.thumbnail}
                        alt={image.alt}
                        w="100%"
                        h={heightImageGrid}
                        objectFit="cover"
                      />
                    </a>
                    <a href={albumHref}>
                      <Button
                        position="absolute"
                        bottom={0}
                        right={0}
                        size="sm"
                        colorScheme="black"
                        p={3}
                        mb={170}
                        mr={3}
                        fontSize="1.2rem"
                        type="button"
                        border="1px"
                        _hover={{ bg: "white", color: "black" }}
                      >
                        Mai multe informatii
                      </Button>
                    </a>
                    {/* Scrollable text container */}
                    <Box
                      fontSize="1.2rem"
                      maxHeight="150px" // Adjust the height as needed
                      overflowY="auto" // Enable vertical scrolling
                      mt={2} // Adjust the margin top as needed
                      p={2} // Add padding to the scrollable box
                      background="white" // Background color of the scrollable box
                      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.25)" // Optional shadow
                    >
                      {image.text}
                    </Box>
                  </GridItem>
                </Box>
              );
            })}
          </Grid>
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      )}
      <Center>
        <Link to="/blog">
          <Button onClick={handleButtonClick} color="white" colorScheme="teal">
            Mai multe postari...
          </Button>
        </Link>
      </Center>
    </>
  );
};

export default BlogPrimaPagina;

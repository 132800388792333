import React from "react";
import {
  Box,
  Center,
  Text,
  Image,
  Button,
  Heading,
  ChakraProvider,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import "./corporate.css";

const Corporate = () => {
  const images = [
    {
      src: "https://images.unsplash.com/photo-1522708323590-d24dbb6b0267?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      text: "Servicii de cazare",
      title: "Oferim Mai Mult Decat Doar Un Loc de Odihna",
      alt: "Servicii de cazare - Corporate",
      description:
        "Serviciile noastre de cazare destinate mediului corporate se intemeiaza pe o sinergie  dintre profesionalism, o atentie la detalii si o retea vasta de unitati de cazare. Aceasta variaza de la hoteluri de lux, hoteluri low-budget, vile atent selectionate pana la pensiuni moderne etc. astfel incat toate solicitarile sa fie solutionate.",
    },
    {
      src: "https://images.unsplash.com/photo-1556388158-158ea5ccacbd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      text: "Servicii de transport aerian",
      title: " Servicii de Transport Aerian pentru Afaceri de Top",
      alt: "Servicii de trasport aerian - Corporate",
      description:
        "Cu serviciile noastre de transport aerian corporate, calatoriile de afaceri devin oportunitati productive, conectandu-va mai eficient cu lumea business-ului global. Contactati-ne astăzi pentru a experimenta avantajele unui transport aerian adaptat nevoilor dvs. ",
    },
    {
      src: "https://images.unsplash.com/photo-1449965408869-eaa3f722e40d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      text: "Servicii de transport terestru",
      title: "Descopera Destinatii Intr-un Mod Confortabil si Elegant",
      alt: "Servicii de trasport terestru - Corporate",
      description:
        "Pentru ca timpul este esential in calatoriile de afaceri, venim in ajutorul tau cu serviciul de inchirieri masini. Acesta cuprinde o gama varianta de masini, de la cele mici pana la cele mari, de tip mini-bus sau chiar masini luxury. Alternativ, poti opta pentru confortul unui transfer.",
    },
    {
      src: "https://images.unsplash.com/photo-1529070538774-1843cb3265df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      text: "M I C E",
      title: "Solutii Complete pentru Evenimente Corporate de Succes",
      alt: "MICE - Corporate",
      description:
        "Bine ai venit in lumea MICE (Meetings, Incentives, Conferences, and Exhibitions), serviciul nostru dedicat evenimentelor corporate! Suntem aici pentru a-ti oferi soluții complete si personalizate pentru planificarea si gestionarea evenimentelor de afaceri.",
    },
    {
      src: "https://images.unsplash.com/photo-1526256262350-7da7584cf5eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      text: "Asigurari Medicale Turistice",
      title: "Protejarea sanatatii tale în vacanta",
      alt: "Asigurari Medicale Turistice",
      description:
        "Travel Time îți oferă asigurare medicală de călătorie pentru toate destinațiile. Alege acoperirea potrivită pentru tine și bucură-te de vacanța ta fără griji.Cu o asigurare medicală de călătorie de la Travel Time, ești acoperit în cazul unor evenimente neprevăzute, cum ar fi un accident, o boală sau o urgență medicală. Asigurarea include, de asemenea, asistență 24/7, astfel încât să poți obține ajutor în cazul unor probleme medicale. \n\nAlege Travel Time pentru o vacanță fără griji!",
    },
    {
      src: "https://images.unsplash.com/photo-1610647752706-3bb12232b3ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1925&q=80",
      text: "Rent-a-car",
      title: "Calatoreste liber cu Travel Time!",
      alt: "Rent-a-car",
      description:
        "Travel Time îți oferă servicii de rent-a-car pentru toate destinațiile. Alege mașina potrivită pentru tine și bucură-te de libertatea de a explora.Cu o mașină de închiriat de la Travel Time, ești liber să explorezi la tine ritmul. Poți vizita locurile pe care le dorești, fără a fi dependent de transportul public sau de tururile organizate.Travel Time îți oferă o gamă largă de mașini de închiriat, pentru toate bugetele și nevoile. De la mașini economice pentru călătoriile de zi cu zi, până la mașini de lux pentru vacanțe de lux, Travel Time are mașina perfectă pentru tine.\n\nAlege Travel Time pentru o vacanță liberă și independentă!",
    },
  ];

  const gridResponsive = useBreakpointValue({ base: "block", lg: "grid" });
  const textResponsive = useBreakpointValue({
    base: "20px",
    md: "30px",
    lg: "45px",
  });
  const descriptionResponsive = useBreakpointValue({
    base: "13px",
    md: "20px",
  });

  return (
    <>
      <ChakraProvider>
        <Link to="/corporate">
          <Center
            className="centerText"
            borderBottom="1px solid"
            fontSize={textResponsive}
            fontWeight="bold"
            color="white"
            textShadow="2px 2px 4px rgba(0, 0, 0)"
            px="6"
            mb="6"
          >
            Corporate Travel
          </Center>
        </Link>
        <Box
          display={gridResponsive}
          gridTemplateColumns="repeat(2, 1fr)"
          gap={2}
        >
          {images.map((image, index) => (
            <Box key={index} className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <Image
                    src={image.src}
                    alt={image.alt}
                    w="100%"
                    h="100%"
                    objectFit="cover"
                  />
                  <Center
                    className="flip-card-text"
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    color="white"
                    textAlign="center"
                    fontSize={textResponsive}
                  >
                    {image.text}
                  </Center>
                </div>
                <div className="flip-card-back">
                  <Heading
                    width="100%"
                    mx="auto"
                    background="teal"
                    color="white"
                    mt="7px"
                    textAlign="center"
                    size="lg"
                    as="h1"
                    mb={4}
                    fontSize={descriptionResponsive}
                  >
                    {image.title}
                  </Heading>
                  <Box className="back-content">
                    <Text fontSize={descriptionResponsive}>
                      {image.description}
                    </Text>
                    <Link to="/contact">
                      <Button
                        fontSize={descriptionResponsive}
                        colorScheme="teal"
                      >
                        Contacteaza-ne
                      </Button>
                    </Link>
                  </Box>
                </div>
              </div>
            </Box>
          ))}
        </Box>
      </ChakraProvider>
    </>
  );
};

export default Corporate;

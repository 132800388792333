// import React from "react";
// import GridComponent from "../grid-component/grid-component";
// import { useParams } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { Flex, Spinner } from "@chakra-ui/react";
// import FourZeroFour from "../../pages/404/404";

// const ReusableComponentDinamicPagesVacante = () => {
//   let { albumId } = useParams();

//   const [paginiDinamice, setpaginiDinamice] = useState([]); // Stocam pozele venite de la localHost
//   const [isValidAlbumId, setIsValidAlbumId] = useState(true); // Adăugăm un state pentru validitatea albumId-ului

//   // Informatii Utile variabile

//   const [titleHeading, setTitleHeading] = useState("");
//   const [descriptionHeading, setDescriptionHeading] = useState("");
//   const [titleHeading1, setTitleHeading1] = useState("");
//   const [descriptionHeading1, setDescriptionHeading1] = useState("");
//   const [titleHeading2, setTitleHeading2] = useState("");
//   const [descriptionHeading2, setDescriptionHeading2] = useState("");
//   const [titleHeading3, setTitleHeading3] = useState("");
//   const [descriptionHeading3, setDescriptionHeading3] = useState("");
//   const [titleHeading4, setTitleHeading4] = useState("");
//   const [descriptionHeading4, setDescriptionHeading4] = useState("");
//   const [titleHeading5, setTitleHeading5] = useState("");
//   const [descriptionHeading5, setDescriptionHeading5] = useState("");

//   // Istorie si Arta variabile

//   useEffect(() => {
//     fetch("/vacante.json").then((res) => {
//       res.json().then((data) => {
//         // const finalResponse = data.paginiInformatiiUtile[albumId];
//         // setpaginiDinamice(finalResponse);

//         const pageTypes = Object.keys(data.paginidinamice);
//         // let pageType = null;
//         let finalResponse = null;

//         pageTypes.forEach((type) => {
//           if (data.paginidinamice[type][albumId]) {
//             // pageType = data.paginidinamice[type][albumId][0].pageType;
//             finalResponse = data.paginidinamice[type][albumId];
//           }
//         });

//         setpaginiDinamice(finalResponse);

//         // Verificăm dacă există un album valid pentru albumId-ul din URL
//         const isValid = finalResponse && finalResponse.length > 0;
//         setIsValidAlbumId(isValid);

//         // Informatii Utile asocieri variabile

//         if (finalResponse && finalResponse.length > 0) {
//           const { title } = finalResponse[0];
//           setTitleHeading(title);
//         }

//         if (finalResponse && finalResponse.length > 0) {
//           const { description } = finalResponse[0];
//           setDescriptionHeading(description);
//         }

//         if (finalResponse && finalResponse.length > 0) {
//           const { title1 } = finalResponse[0];
//           setTitleHeading1(title1);
//         }

//         if (finalResponse && finalResponse.length > 0) {
//           const { description1 } = finalResponse[0];
//           setDescriptionHeading1(description1);
//         }

//         if (finalResponse && finalResponse.length > 0) {
//           const { title2 } = finalResponse[0];
//           setTitleHeading2(title2);
//         }

//         if (finalResponse && finalResponse.length > 0) {
//           const { description2 } = finalResponse[0];
//           setDescriptionHeading2(description2);
//         }

//         if (finalResponse && finalResponse.length > 0) {
//           const { title3 } = finalResponse[0];
//           setTitleHeading3(title3);
//         }

//         if (finalResponse && finalResponse.length > 0) {
//           const { description3 } = finalResponse[0];
//           setDescriptionHeading3(description3);
//         }

//         if (finalResponse && finalResponse.length > 0) {
//           const { title4 } = finalResponse[0];
//           setTitleHeading4(title4);
//         }

//         if (finalResponse && finalResponse.length > 0) {
//           const { description4 } = finalResponse[0];
//           setDescriptionHeading4(description4);
//         }

//         if (finalResponse && finalResponse.length > 0) {
//           const { title5 } = finalResponse[0];
//           setTitleHeading5(title5);
//         }

//         if (finalResponse && finalResponse.length > 0) {
//           const { description5 } = finalResponse[0];
//           setDescriptionHeading5(description5);
//         }
//       });
//     });
//   }, [albumId]);

//   if (!isValidAlbumId) {
//     // Afișăm o pagină de eroare sau o notificare pentru ID nevalid
//     return <FourZeroFour />;
//   }

//   if (paginiDinamice && paginiDinamice.length > 0) {
//     return (
//       <GridComponent
//         images={paginiDinamice}
//         titleHeading={titleHeading}
//         descriptionHeading={descriptionHeading}
//         titleHeading1={titleHeading1}
//         descriptionHeading1={descriptionHeading1}
//         titleHeading2={titleHeading2}
//         descriptionHeading2={descriptionHeading2}
//         titleHeading3={titleHeading3}
//         descriptionHeading3={descriptionHeading3}
//         titleHeading4={titleHeading4}
//         descriptionHeading4={descriptionHeading4}
//         titleHeading5={titleHeading5}
//         descriptionHeading5={descriptionHeading5}
//       />
//     );
//   } else {
//     return (
//       <Flex justifyContent="center" alignItems="center" height="100vh">
//         <Spinner size="xl" />
//       </Flex>
//     );
//   }
// };

// export default ReusableComponentDinamicPagesVacante;

import React from "react";
import GridComponentOferte from "../grid-component/oferte-component";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import FourZeroFour from "../../pages/404/404";

const ReusableComponentDinamicPagesVacanteRevelion = () => {
  let { albumId } = useParams();

  const [paginiDinamice, setpaginiDinamice] = useState([]); // Stocam pozele venite de la localHost
  const [isValidAlbumId, setIsValidAlbumId] = useState(true); // Adăugăm un state pentru validitatea albumId-ului

  const [titleHeading, setTitleHeading] = useState("");

  useEffect(() => {
    fetch("/vacante.json").then((res) => {
      res.json().then((data) => {
        const finalResponse = data.paginidinamice[albumId];
        setpaginiDinamice(finalResponse);

        // Verificăm dacă există un album valid pentru albumId-ul din URL
        const isValid = finalResponse && finalResponse.length > 0;
        setIsValidAlbumId(isValid);

        if (finalResponse && finalResponse.length > 0) {
          const { title } = finalResponse[0];
          setTitleHeading(title);
        }
      });
    });
  }, [albumId]);

  if (!isValidAlbumId) {
    // Afișăm o pagină de eroare sau o notificare pentru ID nevalid
    return <FourZeroFour />;
  }

  if (paginiDinamice && paginiDinamice.length > 0) {
    return (
      <GridComponentOferte
        images={paginiDinamice}
        titleHeading={titleHeading}
      />
    );
  } else {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }
};

export default ReusableComponentDinamicPagesVacanteRevelion;

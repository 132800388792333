import React from "react";

const TermenisiConditii = () => {
  return (
    <>
      <div className="w-75 mx-auto">
        <h1 className="mb-3 text-center">
          REGULILE DE UTILIZARE A WWW.TRAVEL-TIME.RO{" "}
        </h1>
        <p>
          Acest document stabileste regulile de utilizare site-ului
          WWW.TRAVEL-TIME.RO. Va rugam sa cititi aceasta pagina cu atentie. Daca
          nu acceptati termenii si conditiile statuate in acest document, nu
          folositi site-ul WWW.TRAVEL-TIME.RO si serviciile oferite prin
          intermediul lui. Prin utilizarea site-ului, indicati ca acceptati sa
          respectati aceste reguli. Autorii acestui site , pot revizui acest
          document oricand, prin actualizarea acestei pagini. Va recomandam sa
          vizitati aceasta pagina periodic pentru a fi la curent cu drepturile
          si obligatiile dvs. Prin termenul de "utilizator" intelegem toate
          persoanele fizice si/sau juridice care acceseaza siteul
          WWW.TRAVEL-TIME.RO, indiferent de motiv, nationalitate sau locatie
          geografica.{" "}
        </p>
        <br />
        <b>Utilizarea materialelor </b>
        <br />
        <br />
        <p>
          * TRAVEL TIME D&R va permite sa vizualizati si sa obtineti o singura
          copie a materialelor de pe site-ul WWW.TRAVEL-TIME.RO doar in scopuri
          personale si pentru uz necomercial. Continutul acestui site, textele,
          grafica, fotografiile, software-ul precum si alte materiale sunt
          protejate de legea dreptului de autor. Intregul material este
          proprietatea TRAVEL TIME D&R . Folosirea a informatiilor de pe site-ul
          WWW.TRAVEL-TIME.RO fara permisiunea scrisa si expresa a autorilor
          acestuia reprezinta o incalcare a legii dreptului de autor (prevazut
          de Legea 8/1996 si/sau alte legi).
        </p>
        <br />
        <b>Conditiile de utilizare a siteului </b>
        <br />
        <br />
        <p>
          * Site-ul WWW.TRAVEL-TIME.RO poate fi utilizat doar in scopuri legale.
          Orice alta modalitate de utilizare a siteului este expres interzisa de
          TRAVEL TIME D&R . In mod special este interzisa obtinerea
          informatiilor de pe site-ul WWW.TRAVEL-TIME.RO pe alta cale decat prin
          accesarea paginilor web, cum ar fi incercarile de obtinere a parolelor
          de acces in zone neautorizate, sau executarea de coduri, programe
          executabile sau orice astfel de instrumente cu scopul de a accesa
          informatiile in moduri nepermise de structura site-ului
          WWW.TRAVEL-TIME.RO.
        </p>
        <br />
        <b>Informatii pentru utilizatori</b>
        <br /> <br />
        <p>
          * In cazul in care va inregistrati pe site-ul WWW.TRAVEL-TIME.RO, vi
          se vor cere anumite informatii incluzand o adresa de e-mail valida.
          Dumneavoastra sunteti obligati sa intorduceti numai informatii corecte
          si reale, si acceptati consecintele juridice ale nerespectarii acestei
          conditii. TRAVEL TIME D&R nu va divulga niciodata unei terte persoane
          informatiile continute in cererea dumneavoastra de inregistrare; cu
          toate acestea, informatiile introduse de dvs. pot fi luate in
          considerare la efectuarea unor statistici, respectandu-va totala
          anonimitate. TRAVEL TIME D&R isi rezerva dreptul de a va oferi
          serviciile si produsele unui tert, bazandu-se pe preferintele
          mentionate in inregistrarea dumneavoastra in orice moment dupa ce
          aceasta a avut loc; asemenea oferte pot fi facute de catre TRAVEL TIME
          D&R sau de catre un tert implicat.
        </p>
        <br />
        <b>
          Informatii generale legate de utilizarea site-uluiWWW.TRAVEL-TIME.RO
        </b>{" "}
        <br /> <br />
        <p>
          * TRAVEL TIME D&R nu garanteaza credibilitatea, acuratetea
          informatiilor publicate de catre utilizatori (fie ei simpli vizitatori
          sau reprezentanti ai companiilor aeriene inscrise pe site) sau nu
          gireaza vreo parere exprimata de catre acestia.
        </p>{" "}
        <p>
          * Utilizatorul este singurul responsabil de pastrarea
          confidentialitatii asupra informatiilor si a parolei sale. De
          asemenea, utilizatorul raspunde pentru utilizarea inregistrarii sale,
          indiferent daca utilizarea se face de el sau de o alta persoana, cu
          sau fara voia sa. Utilizatorul se obliga sa sesizeze autorilor
          site-ului orice abuz privind inregistrarea sa.{" "}
        </p>{" "}
        <p>
          * Folosirea site-ului WWW.TRAVEL-TIME.RO este gratuita, indiferent de
          serviciile folosite de utilizator. TRAVEL TIME D&R isi rezerva dreptul
          de a introduce taxe de utilizare pentru oricare sau toate serviciile,
          obligandu-se in acelasi timp sa notifice in timp util pe cei carora li
          se vor aplica respectivele tarife.
        </p>{" "}
        <p>
          {" "}
          * In cazul in care utilizatorul depaseste limitele impuse de acest
          contract in utilizarea site-ului WWW.TRAVEL-TIME.RO, TRAVEL TIME D&R
          isi rezerva dreptul de a lua masurile sanctionatorii considerate
          necesare, care pot include fara a fi limitate la: anularea
          inregistrarii utilizatorului; interzicerea accesului utilizatorului la
          oricare sau toate serviciile oferite; actionarea in justitie.
          Utilizatorul intelege si este de acord sa depagubeasca TRAVEL TIME D&R
          pentru orice pagube pricinuite prin fapta sa. Informatii legate de
          rezervarile online prin WWW.TRAVEL-TIME.RO * Din motive obiective,
          TRAVEL TIME D&R nu garanteaza veridicitatea informatiilor introduse de
          utilizatorii care folosesc serviciul de rezervari online.
        </p>{" "}
        <p>
          * Utilizatorul care alege sa se foloseasca de acest serviciu se obliga
          sa introduca in cererea sa informatii corecte si reale, si sa nu
          abuzeze in nici un fel de acest serviciu. In caz contrar, TRAVEL TIME
          D&R isi rezerva dreptul de a lua masurile sanctionatorii considerate
          necesare, care pot include fara a fi limitate la: anularea
          inregistrarii utilizatorului; interzicerea accesului utilizatorului la
          oricare sau toate serviciile oferite; actionarea in justitie.
          Utilizatorul intelege si este de acord sa depagubeasca TRAVEL TIME D&R
          pentru orice pagube pricinuite prin fapta sa.
        </p>
      </div>
    </>
  );
};

export default TermenisiConditii;

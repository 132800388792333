import React from "react";
import { Box } from "@chakra-ui/react";

const ComunicatDePresa3 = () => {
  return (
    <Box>
      <Box
        width={{ base: "75%", lg: "50%" }}
        mx="auto"
        mb="5px"
        pr={{ base: 0, lg: "0.5rem" }} // Add spacing between images
      >
        <img
          src="/images/comunicat-de-presa-22nov23-1.JPG"
          alt="comunicat de presa"
          width="100%"
          height="auto"
        />
      </Box>
      <Box
        width={{ base: "75%", lg: "50%" }}
        mx="auto"
        pl={{ base: 0, lg: "0.5rem" }} // Add spacing between images
      >
        <img
          src="/images/comunicat-de-presa-22nov23-2.JPG"
          alt="comunicat de presa"
          width="100%"
          height="auto"
        />
      </Box>
    </Box>
  );
};

export default ComunicatDePresa3;

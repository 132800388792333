import ReusableComponentDinamicPagesOferte from "../../components/reusable-component-oferte/reusable-component-categorii-vacante";

const Revelion = () => {
  const countryButtons = [
    { text: "Amsterdam", value: "Amsterdam" },
    { text: "Antalya", value: "Antalya" },
    { text: "Atena", value: "Atena" },
    { text: "Bali", value: "Bali" },
    { text: "Madrid", value: "Madrid" },
    { text: "Malta", value: "Malta" },
    { text: "Maroc", value: "Maroc" },
    { text: "Paris", value: "Paris" },
    { text: "Roma", value: "Roma" },
    { text: "Sri Lanka", value: "Sri Lanka" },
    { text: "Thailanda", value: "Thailanda" },
    { text: "Viena", value: "Viena" },
  ];

  const currentContinent = "Revelion 2024"; //setarea titlului
  const currentContinentName = "Revelion";
  const backgroundImageURL =
    "https://images.unsplash.com/photo-1498931299472-f7a63a5a1cfa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2073&q=80"; // Set the background image URL for this page

  return (
    <ReusableComponentDinamicPagesOferte
      countryButtons={countryButtons}
      titlePage={currentContinent}
      currentContinent={currentContinentName}
      bgImage={backgroundImageURL}
    />
  );
};

export default Revelion;

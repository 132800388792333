import ReusableComponentContinente from "../../components/grid-component/reusable-continente-component";

const Europa = () => {
  const countryButtons = [
    { text: "Franta", value: "franta" },
    { text: "Grecia", value: "grecia" },
    { text: "Spania", value: "spania" },
    { text: "Germania", value: "germania" },
    { text: "Marea Britanie", value: "Marea Britanie" },
    { text: "Croatia", value: "Croatia" },
    { text: "Bulgaria", value: "Bulgaria" },
    { text: "Finlanda", value: "Finlanda" },
    { text: "Portugalia", value: "Portugalia" },
    { text: "Elvetia", value: "Elvetia" },
    { text: "Slovenia", value: "Slovenia" },
    { text: "Olanda", value: "Olanda" },
    { text: "Italia", value: "Italia" },
    { text: "Austria", value: "Austria" },
    { text: "Cehia", value: "Cehia" },
    { text: "Suedia", value: "Suedia" },
    { text: "Islanda", value: "Islanda" },
    { text: "Norvegia", value: "Norvegia" },
    { text: "Rusia", value: "Rusia" },
  ];

  const continentList = [
    { name: "Asia", url: "/asia/" },
    { name: "America De Sud", url: "/americadesud/" },
    { name: "America De Nord", url: "/americadesud/" },
    { name: "Africa", url: "/africa/" },
    { name: "Romania", url: "/romania/" },
    { name: "Australia si Oceania", url: "/australiasioceania/" },
    // Add more continents and URLs as needed
  ];

  const currentContinent = "Europa";
  const backgroundImageURL =
    "https://www.travel-time.ro/images/header-blog/Europa.jpg"; // Set the background image URL for this page

  return (
    <ReusableComponentContinente
      countryButtons={countryButtons}
      titlePage={currentContinent}
      currentContinent={currentContinent}
      bgImage={backgroundImageURL}
      continentList={continentList}
    />
  );
};

export default Europa;

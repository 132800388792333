import ReusableComponentContinente from "../../components/grid-component/reusable-continente-component";

const AustraliaSiOceania = () => {
  const countryButtons = [
    { text: "Australia", value: "Australia" },
    { text: "Oceania", value: "Oceania" },
  ];

  const continentList = [
    { name: "Europa", url: "/europa/" },
    { name: "America De Sud", url: "/americadesud/" },
    { name: "America De Nord", url: "/americadesud/" },
    { name: "Africa", url: "/africa/" },
    { name: "Romania", url: "/romania/" },
    { name: "Asia", url: "/asia/" },
    // Add more continents and URLs as needed
  ];

  const currentContinent = "Australia si Oceania"; //setarea titlului
  const currentContinentName = "AustraliaSiOceania"; //prop pentru setarea endpointului din json
  const backgroundImageURL =
    "https://www.travel-time.ro/images/header-blog/Austrialia-Oceania.jpg"; // Set the background image URL for this page

  return (
    <ReusableComponentContinente
      countryButtons={countryButtons}
      titlePage={currentContinent}
      currentContinent={currentContinentName}
      bgImage={backgroundImageURL}
      continentList={continentList}
    />
  );
};

export default AustraliaSiOceania;

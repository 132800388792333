import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Heading,
  Center,
  Text,
  Stack,
  Flex,
  Icon,
  Image,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { GiAirplaneDeparture } from "react-icons/gi";
import { Link } from "react-router-dom";

const categories = [
  {
    icon: GiAirplaneDeparture, // Replace with actual icon
    title: "Amadeus GDS",
    text: "este lider global în furnizarea de soluții tehnologice pentru industria biletelor de avion si a călătoriilor. Oferă acces rapid și usor la un vast inventar de bilete de avion, cu peste 400 de companie aeriene de linie, low cost, regionale sau hybrid. Agentul de turism este conectat la disponibilitatea companiile aeriene printr-un instrument complet, efficient, performant și bine integrat în platforma noastră, ce permite gestionarea și emiterea rapidă a biletelor de avion. ",
    logo: "/images/furnizori/Amadeus-Logo.jpg",
  },
  {
    icon: GiAirplaneDeparture,
    title: "NDC-X Amadeus  ",
    text: "este un instrument de top ce integrează noul standard de distribuție NDC (New Distribution Capability), modernizând modul în care sunt vândute și gestionate biletele de avion. Astfel este posibilă conectarea directă cu companiile aeriene pentru accesarea tarifelor personalizate, negociate, flexibile si a diverselor servicii adiționale propuse.",
    logo: "/images/furnizori/Amadeus-Logo.jpg",
  },
  {
    icon: GiAirplaneDeparture,
    title: "Farelogix NDC Lufthansa Group",
    text: "este un furnizor de top în distributia biletelor de avion în cadrul grupului Lufthansa, utilizând noul standard de distribuție NDC (New Distribution Capability) pentru a permite accesul direct la inventarul companiilor aeriene Lufthansa, Austrian Airlines, Swiss Air, SN Brussels, Discover Airlines și Eurowings. Acest standard modernizează modul în care biletele de avion sunt vândute și gestionate, oferind agenților revânzători un avantaj competitiv realș permite conectarea directă cu inventarul companiile aeriene, oferind tarife personalizate, negociate, opțiuni flexibile și o serie întreagă de servicii auxiliare. Farelogix oferă subagenților revânzători instrumentele necesare pentru a oferi clienților cele mai bune opțiuni de călătorie, îmbunătățind în același timp eficiența și profitabilitatea. ",
    logo: "/images/furnizori/Lufthansa-logo.jpg",
  },
  {
    icon: GiAirplaneDeparture,
    title: "Farelogix NDC Aegeean Airlines ",
    text: "este soluția oferită de compania aviatică din Grecia, agentilor de ticketing, menită să integreze noul standard de distribuție NDC (New Distribution Capability) prin care sunt acoperite toate rutele companiei Aegeean Airlines și cele mai performate tarife, actualizate în timp real, alturi de cele mai diverse servicii auxiliare.",
    logo: "/images/furnizori/Aegean-Airlines-Logo.jpg",
  },
  {
    icon: GiAirplaneDeparture,
    title: "Travelfusion",
    text: "este un lider global în tehnologia de distribuție a călătoriilor, oferind soluții avansate prin conținut LLC (Low-Cost Carrier) dar și prin noul standard de distribuție NDC (New Distribution Capability). Prin integrarea Travelfusion am oferit subagenților revânzători instrumentele necesare accesarii a peste 300 de companie low-cost din întreaga lume și a peste 25 de companie de linie ce au adoptat NDC. ",
    logo: "/images/furnizori/Travelfusion-Logo-colour-high-res-01.jpg",
  },
];

const CategoryBox = ({ icon, text, title, logo }) => {
  // const displayBox = useBreakpointValue({ base: "block", md: "flex" });
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const textAlign = useBreakpointValue({ base: "center" });
  return (
    <Box
      p={4}
      alignItems="center"
      borderWidth="1px"
      borderRadius="lg"
      width="100%"
      mb={4}
      _hover={{ shadow: "md" }}
    >
      <Flex direction={flexDirection} alignItems="center">
        <Center>
          <Icon as={icon} boxSize={6} mr={4} aria-label={title} />
        </Center>
        <Flex
          direction="column"
          align={textAlign === "center" ? "center" : "flex-start"}
          flex="1"
        >
          <Center fontSize={20} fontWeight={600} mb={2}>
            {title}
          </Center>
          <Center mb={2}>
            <Image src={logo} alt={`${title} logo`} maxW="150px" />
          </Center>
          <Text textAlign={textAlign}>{text}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

const Ticketing = () => {
  const history = useNavigate();

  const handleButtonClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Navigate to the '/vacante' route
    history.push("/contact");
  };

  return (
    <Box
      // backgroundImage="url('/background.svg')"
      backgroundSize="cover"
      backgroundPosition="center"
      py={5}
      id="ticketing"
    >
      <Container maxW="container.md">
        <Heading
          as="h2"
          size="xl"
          mb={6}
          ml={5}
          textAlign="center"
          color="black"
        >
          Servicii Aeriene
        </Heading>
        <Stack spacing={4}>
          {categories.map((category, index) => (
            <CategoryBox
              key={index}
              icon={category.icon}
              title={category.title}
              text={category.text}
              logo={category.logo}
            />
          ))}
        </Stack>
        <Center
          justify="center"
          align="center"
          as="h1"
          fontSize={{ base: "2xl", md: "3xl" }}
          mt="15px"
        >
          <Link to="/contact">
            <Button
              fontSize="20px"
              target="_blank"
              colorScheme="red"
              variant="outline"
              mr={4}
              color="red"
              bg="white.400"
              borderRadius="25px"
              _hover={{
                boxShadow: "4px 4px rgba(255, 99, 71,0.9)",
              }}
              onClick={handleButtonClick}
            >
              Solicita un cont demo!
            </Button>
          </Link>
        </Center>
      </Container>
    </Box>
  );
};

export default Ticketing;

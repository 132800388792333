import {
  Box,
  Button,
  Image,
  useBreakpointValue,
  Heading,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import "./oferte-component.css";

const GridComponentOferte = ({ images, titleHeading, descriptionHeading }) => {
  const widthResponsive = useBreakpointValue({ base: "100%", md: "75%" });
  const widthResponsiveHeading = useBreakpointValue({
    base: "100%",
    md: "75%",
    lg: "50%",
  });
  const fontSizeResponsive = useBreakpointValue({ base: "xl", md: "2xl" });

  return (
    <>
      <Box
        borderColor="gray.100"
        borderWidth="2px"
        mt="25px"
        width={widthResponsive}
        mx="auto"
      >
        <Heading
          className="heading"
          width="100%"
          mx="auto"
          background="teal"
          color="white"
          textAlign="center"
          size={fontSizeResponsive}
          as="h1"
          mb="10px"
          fontFamily="Montserrat, sans-serif"
        >
          {titleHeading}
        </Heading>
        <Box width="75%" mx="auto">
          <Image
            src={images[0].img}
            alt={images[0].alt}
            width="100%"
            objectFit="cover"
          />
        </Box>

        <Box p={4}>
          <Heading
            width={widthResponsiveHeading}
            mx="auto"
            textAlign="center"
            size="md"
            as="h2"
          >
            Pentru mai multe detalii va rugam sa ne contactati:
          </Heading>
          <Link to="/contact">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                colorScheme="teal"
                align="center"
                type="submit"
                mt="10px"
                size="lg"
                _hover={{ bg: "rgba(255, 0, 0, 0.7)" }}
              >
                Contacteaza-ne!
              </Button>
            </div>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default GridComponentOferte;

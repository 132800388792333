import ReusableComponentContinente from "../../components/grid-component/reusable-continente-component";

const AmericaDeNord = () => {
  const countryButtons = [
    { text: "Statele Unite ale Americii", value: "Statele Unite ale Americii" },
    { text: "Canada", value: "Canada" },
    { text: "Mexic", value: "Mexic" },
    { text: "Republica Dominicana", value: "Republica Dominicana" },
  ];

  // Define a list of continents with their names and URLs
  const continentList = [
    { name: "Europa", url: "/europa/" },
    { name: "Asia", url: "/asia" },
    { name: "America De Sud", url: "/americadesud/" },
    { name: "Africa", url: "/africa/" },
    { name: "Romania", url: "/romania/" },
    { name: "Australia si Oceania", url: "/australiasioceania/" },
    // Add more continents and URLs as needed
  ];

  const currentContinent = "America de Nord"; //setarea titlului
  const currentContinentName = "AmericaDeNord"; //prop pentru setarea endpointului din json
  const backgroundImageURL =
    "https://www.travel-time.ro/images/header-blog/America-de-Nord.jpg"; // Set the background image URL for this page

  return (
    <ReusableComponentContinente
      countryButtons={countryButtons}
      titlePage={currentContinent}
      currentContinent={currentContinentName}
      bgImage={backgroundImageURL}
      continentList={continentList}
    />
  );
};

export default AmericaDeNord;

import { useNavigate } from "react-router-dom";
import { Box, Button, useBreakpointValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import irixImage from "./irix.PNG";

const HeaderImage = ({ HeaderImg }) => {
  const widthResponsive = useBreakpointValue({
    base: "100%",
    lg: "90%",
  });

  const heightResponsive = useBreakpointValue({
    base: "300px",
    lg: "500px",
  });

  const borderRadiusResponsive = useBreakpointValue({
    base: "5px",
    lg: "20px",
  });

  const history = useNavigate();

  const handleButtonClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Navigate to the '/vacante' route
    history.push("/contact");
  };

  return (
    <header>
      <Box
        className="p-5 text-center bg-image"
        width={widthResponsive}
        height={heightResponsive}
        borderRadius={borderRadiusResponsive}
        mx="auto"
        mt="10px"
        style={{
          backgroundImage: `url(${irixImage})`,
        }}
      >
        <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-white">
              <h1 className="display-6 mb-10">
                Booking Time: solutia ideala pentru agentii.
              </h1>
              <Link to="/contact">
                <Button
                  onClick={handleButtonClick}
                  as="a"
                  variant="outline"
                  target="_blank"
                  size="lg"
                  colorScheme="white"
                  _hover={{ color: "teal" }}
                >
                  Incearca acum!
                </Button>
              </Link>

              <Button
                href="https://tbs.flytime.ro/reseller/auth/"
                colorScheme="white"
                as="a"
                size="lg"
                variant="outline"
                marginLeft="10px"
                _hover={{ color: "teal" }}
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </header>
  );
};

export default HeaderImage;

import React from "react";
import CardIrixPresentation from "../../components/b2b-irix-card/b2b-irix-card";
import B2bHeader from "../../components/b2b-header/b2b-header";
import Hotels from "../../components/irix-detalis/Hotels";
import Ticketing from "../../components/irix-detalis/Ticketing";

const B2B = () => {
  return (
    <>
      <B2bHeader />
      <Ticketing />
      <Hotels />
      <CardIrixPresentation />
    </>
  );
};

export default B2B;

import { Flex, Box } from "@chakra-ui/react";
import {
  Grid,
  GridItem,
  Image,
  Button,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";

export const GridPromoKlNov = () => {
  const columns = useBreakpointValue({ base: 1, md: 2 });

  const heightGrid = useBreakpointValue({
    base: "250px",
    lg: "400px",
  });

  const widthGrid = useBreakpointValue({
    base: "95%",
    lg: "95%",
  });

  const widthResponsiveHeading = useBreakpointValue({
    base: "100%",
    md: "75%",
    lg: "50%",
  });

  const contentKLM = [
    {
      title: "BOSTON(BOS)",
      price: "Începând de la 120 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Boston.jpg",
    },
    {
      title: "NEW YORK (NYC)",
      price: "Începând de la 537 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/KLEconomic/New York.jpg",
    },
    {
      title: "LOS ANGELES(LAX)",
      price: "Începând de la 712 euro",
      thumbnail:
        "https://travel-time.ro/images/af-kl/KLEconomic/Los Angeles.jpg",
    },
    {
      title: "TAMPA(TPA)",
      price: "Începând de la 320 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/KLEconomic/Tampa.jpg",
    },
    {
      title: "TORONTO(YTO)",
      price: "Începând de la 320 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/KLEconomic/Toronto.jpg",
    },
    {
      title: "ORLANDO(MCO)",
      price: "Începând de la 320 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/KLEconomic/Orlando.jpg",
    },
  ];

  return (
    <>
      {/* <Center
        className="centerText"
        borderBottom="1px solid"
        fontSize={textResponsive}
        fontWeight="bold"
        color="#0a1373"
        textShadow="2px 2px 4px rgba(0, 0, 0)"
        px="6"
        mb="6"
      >
        Air France Destination
      </Center> */}
      <Heading
        width={widthResponsiveHeading}
        mx="auto"
        color="#00d4ff"
        mt="7px"
        textAlign="center"
        size="lg"
        as="h1"
        mb={4}
      >
        <Image
          src="/images/af-kl/KLM-logo.png"
          alt="images"
          w="500px"
          mx="auto"
          objectFit="cover"
        />
      </Heading>

      <Flex mt="10px" flexWrap="wrap" justifyContent="center">
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
          {contentKLM.map((image) => {
            return (
              <Box key={image.id} mb={6}>
                <GridItem position="relative" width={widthGrid} mx="auto">
                  <Box
                    className="description-title"
                    mx="auto"
                    p={2}
                    mb={3}
                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                    display="flex"
                    justifyContent="center"
                    background="#00a1de"
                    color="white"
                    fontWeight="bold"
                    fontSize="1.2rem"
                  >
                    {image.title}
                  </Box>
                  <a href="https://www.klm.ro/travel-guide/destinations">
                    <Image
                      src={image.thumbnail}
                      alt={image.alt}
                      w="100%"
                      h={heightGrid}
                      objectFit="cover"
                    />
                  </a>

                  <a href="https://www.klm.ro/travel-guide/destinations">
                    <Button
                      position="absolute"
                      bottom={0}
                      left="50%"
                      transform="translateX(-50%)"
                      size="sm"
                      background="white"
                      // colorScheme="black"
                      color="black"
                      p={3}
                      mb={3}
                      opacity="0.9"
                      mr={3}
                      fontSize="1.2rem"
                      type="button"
                      border="1px"
                      // _hover={{ bg: "white", color: "black" }}
                    >
                      Călătorește acum cu KLM!
                    </Button>
                  </a>
                </GridItem>
              </Box>
            );
          })}
        </Grid>
      </Flex>
    </>
  );
};

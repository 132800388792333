import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import "./b2b-header.css";

const B2bHeader = () => {
  return (
    <header>
      <div
        className="p-5 text-center bg-image mb-3"
        style={{
          backgroundImage:
            "url('https://www.dcsplus.net/hubfs/banner_IRIX_1903x524_v2c.jpg')",
          height: "400px",
        }}
      >
        <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-white">
              <h1 className="mb-3 title-heading" style={{ fontSize: "34px" }}>
                BOOKING TIME
              </h1>
              <Link to="/contact">
                <Button
                  as="a"
                  variant="outline"
                  target="_blank"
                  size="lg"
                  colorScheme="teal"
                  _hover={{ color: "white" }}
                >
                  Incearca acum!
                </Button>
              </Link>

              <Button
                href="https://tbs.flytime.ro/reseller/auth/"
                colorScheme="teal"
                as="a"
                size="lg"
                variant="outline"
                marginLeft="10px"
                _hover={{ color: "white" }}
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default B2bHeader;

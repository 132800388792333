import ReusableComponentContinente from "../../components/grid-component/reusable-continente-component";

const Africa = () => {
  const countryButtons = [
    { text: "Egipt", value: "Egipt" },
    { text: "Angola", value: "Angola" },
    { text: "Zimbabwe", value: "Zimbabwe" },
    { text: "Tanzania", value: "Tanzania" },
    { text: "Botswana", value: "Botswana" },
    { text: "Madagascar", value: "Madagascar" },
    { text: "Kenya", value: "Kenya" },
    { text: "Mauritius", value: "Mauritius" },
    { text: "Africa de Sud", value: "Africa de Sud" },
  ];

  // Define a list of continents with their names and URLs
  const continentList = [
    { name: "Europa", url: "/europa/" },
    { name: "Asia", url: "/asia" },
    { name: "America De Sud", url: "/americadesud/" },
    { name: "America De Nord", url: "/americadenord/" },
    { name: "Romania", url: "/romania/" },
    { name: "Australia si Oceania", url: "/australiasioceania/" },
    // Add more continents and URLs as needed
  ];

  const currentContinent = "Africa";
  const backgroundImageURL =
    "https://www.travel-time.ro/images/header-blog/Africa.jpg"; // Set the background image URL for this page

  return (
    <ReusableComponentContinente
      countryButtons={countryButtons}
      titlePage={currentContinent}
      currentContinent={currentContinent}
      bgImage={backgroundImageURL}
      continentList={continentList}
    />
  );
};

export default Africa;

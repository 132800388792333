import ReusableComponentContinente from "../../components/grid-component/reusable-continente-component";

const Asia = () => {
  const countryButtons = [
    { text: "Emiratele Arabe Unite", value: "Emiratele Arabe Unite" },
    { text: "Thailanda", value: "Thailanda" },
    { text: "Turcia", value: "Turcia" },
    { text: "Japonia", value: "Japonia" },
    { text: "China", value: "China" },
    { text: "Indonezia", value: "Indonezia" },
    { text: "Maldive", value: "Maldive" },
    { text: "India", value: "India" },
    { text: "Vietnam", value: "Vietnam" },
    { text: "Myanmar", value: "Myanmar" },
    { text: "Filipine", value: "Filipine" },
    { text: "Yemen", value: "Yemen" },
    { text: "Singapore", value: "Singapore" },
    { text: "Cambodgia", value: "Cambodgia" },
  ];

  const continentList = [
    { name: "Europa", url: "/europa/" },
    { name: "America De Sud", url: "/americadesud/" },
    { name: "America De Nord", url: "/americadesud/" },
    { name: "Africa", url: "/africa/" },
    { name: "Romania", url: "/romania/" },
    { name: "Australia si Oceania", url: "/australiasioceania/" },
    // Add more continents and URLs as needed
  ];

  const currentContinent = "Asia";
  const backgroundImageURL =
    "https://www.travel-time.ro/images/header-blog/Asia.jpg"; // Set the background image URL for this page

  return (
    <ReusableComponentContinente
      countryButtons={countryButtons}
      titlePage={currentContinent}
      currentContinent={currentContinent}
      bgImage={backgroundImageURL}
      continentList={continentList}
    />
  );
};

export default Asia;

import React from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css";

function CarouselHomePage() {
  const widthResponsive = useBreakpointValue({
    base: "100%",
    lg: "85%",
  });

  const carouselStyle = {
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: "25px",
  };

 
  return (
    <Box
      style={carouselStyle}
      width={widthResponsive}
      className="carousel-container"
    >
      <Carousel
        showThumbs={false}
        swipeable={true}
        infiniteLoop={true}
        autoPlay={true}
        stopOnHover={true}
        dynamicHeight={false}
        useKeyboardArrows={true}
      >
        <div className="carousel-container">
          <img
            src="https://images.unsplash.com/photo-1618773928121-c32242e63f39?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
            alt="Servicii de cazare - Leisure"
          />
          <p className="legend">
            Cazarea reprezinta inceputul fiecarei calatorii si ne asiguram ca
            incepi intr-un mod excelent. Colaboram cu o retea vasta de hoteluri,
            vile si apartamente pentru a-ti oferi variante de cazare care se
            potrivesc fiecarui stil si buget. Fie ca preferi luxul rafinat al
            unui hotel de 5 stele sau intimitatea unei vile private, avem
            solutii pentru toate gusturile.
          </p>
        </div>
        <div>
          <img
            src="https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2074&q=80"
            alt="Servicii de transport aerian - Leisure"
          />
          <p className="legend">
            Indiferent dacă calatoriti pentru relaxare sau aventura, serviciile
            noastre de transport aerian asigura că veti ajunge la destinatie cu
            un zambet pe buze. Echipa noastra dedicată este aici să vă ofere
            asistentă și sa va transforme calatoria într-o experiență de
            neuitat. Contactati-ne astăzi pentru a începe planificarea
            călătoriei dvs. leisure!
          </p>
        </div>
        <div>
          <img
            src="https://images.unsplash.com/photo-1581687719747-69e6c7507313?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
            alt="Servicii de transport terestru - Leisure"
          />
          <p className="legend">
            Indiferent dacă calatoriti într-un grup sau singuri, serviciile
            noastre de transport terestru sunt create pentru a face calatoria
            parte integranta a aventurilor dvs. leisure. Cu atentie la detalii
            și o abordare personalizata, suntem aici sa va asiguram o calatorie
            memorabila de la început până la sfarsit. Contactati-ne acum pentru
            a începe planificarea calatoriei!
          </p>
        </div>
        <div>
          <img
            src="https://images.unsplash.com/photo-1601202278710-3bf072d6c950?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
            alt="Pachete Turistice - Leisure"
            className="carousel-image"
          />
          <p className="legend">
            În cadrul agenției noastre de turism, am creat cu pasiune și atenție
            fiecare pachet pentru a vă oferi experiențe de neuitat în timpul
            călătoriilor de agrement.Indiferent dacă doriți să explorați
            destinații exotice, să vă relaxați pe plajă, să vă bucurați de
            aventuri montane sau să explorați bogățiile culturale ale lumii,
            avem pachete concepute pentru a vă îndeplini fiecare dorință și vis.
          </p>
        </div>
        {/* Add more slides as needed */}
      </Carousel>
    </Box>
  );
}

export default CarouselHomePage;

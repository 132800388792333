import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieContent = () => {
  const trackingScripts = () => {
    // Implement your tracking codes here
  };
  return (
    <div>
      {/* Your other React components go here */}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="myCookieConsent"
        enableDeclineButton
        onAccept={() => trackingScripts()}
      >
        De ce cookie-uri? Le utilizam pentru a optimiza functionalitatea site-ului web si pentru a intelege mai bine modul in care site-ul este utilizat.
      </CookieConsent>
    </div>
  );
};

export default CookieContent;

import React from "react";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  Divider,
  Textarea,
  FormHelperText,
  Input,
  Button,
  Heading,
  Box,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import ReactGA from "react-ga4";

const FormContact2222222 = () => {
  const form = useRef();
  const [isPending, setIsPending] = useState(false);
  const history = useNavigate();
  const toast = useToast();
  const showToast = () => {
    toast({
      position: "top",
      title: "Mesaj Trimis",
      description: "Mesaj trimis cu succes",
      duration: 5000,
      isClosable: true,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    setIsPending(true);

    emailjs
      .sendForm(
        "service_4vhsjzg",
        "template_u9vdbgs",
        form.current,
        "cvwaMScxKKMXrSpbl"
      )
      .then((result) => {
        console.log(result.text);
        setIsPending(false);

        // Adăugați codul de urmărire a conversiei aici
        ReactGA.send({
          hitType: "event",
          eventCategory: "Formular de contact",
          eventAction: "Trimitere mesaj",
          eventLabel: "Conversie",
        });
        history("/");
        showToast();
      });
  };

  const headingSize = useBreakpointValue({ base: "md", md: "2xl" });
  const marginBottom = useBreakpointValue({ base: "6px", md: "10px" });
  const widthBox = useBreakpointValue({ base: "75%", md: "50%" });

  return (
    <>
      <Box className="mx-auto" width={widthBox} mt="10px">
        <Heading
          align="center"
          color="blue.400"
          as="h1"
          size={headingSize}
          mb={marginBottom}
        >
          Contacteaza-ne!
        </Heading>

        <form ref={form} onSubmit={sendEmail}>
          <FormControl mb="10px">
            <Input type="text" name="user_name" placeholder="Nume" required />
          </FormControl>
          <FormControl mb="10px">
            <Input
              type="email"
              name="user_email"
              placeholder="example@gmail.com"
              required
            />
          </FormControl>
          <FormControl mb="10px">
            <Input
              type="number"
              name="mobile_number"
              placeholder="Numar Telefon"
              required
            />
          </FormControl>

          <FormControl>
            <FormHelperText mb="5px">Solicitarea dumneavoastra </FormHelperText>
            <Textarea
              mb="10px"
              name="message"
              placeholder="Mesaj"
              rows="4"
              required
            />
          </FormControl>

          {!isPending && (
            <Button colorScheme="teal" align="center" type="submit">
              Trimite Mesaj
            </Button>
          )}
          {isPending && (
            <Button align="center" type="submit">
              Se trimite...
            </Button>
          )}
        </form>
      </Box>

      <Divider mt="30px" />
    </>
  );
};

export default FormContact2222222;
